import React from "react"
import { Carousel } from 'antd';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

const captions = {
    "im001": "Stuffed Bear Sitting On The Table",
    "im002": "Small Kids Are About To Be Picked From The Grass",
    "im003": "Cardboard Box",
    "im004": "Man Holding A Great Camera",
    "im005": "Colorful UmbrellaBlue Commercial Air Plane Is Parked On A Tropical Space",
    "im006": "Lone Boat On A Body Of Water",
}

const GeneratedCaptionsCarousel = () => {
    const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativePath: {regex: "/2019-05-29-nic/images/"}}) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
            small: fluid(maxWidth: 800) {
              src
              srcSet
              aspectRatio
              sizes
              tracedSVG
              }          
            }
          }
        }
      }
    }
    `)
    return(        
      <Carousel style={{background: "black"}} autoplay>
        {
          data.allFile.edges.map((file, index) => {
            return (
              <div key={`nic-car-${index}`}>
                <h3 style={{fontWeight: 200}}>{captions[file.node.name]}</h3>
                <Img fluid={{...file.node.childImageSharp.small, aspectRatio: 1.8}} />
              </div>
            )
          })
        }
    </Carousel>
    )
  }

export default GeneratedCaptionsCarousel;
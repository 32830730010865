import React from "react"
import {Button, Row, Col, Divider, Popover } from 'antd';
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

const ImageGridRow = (props) => {
  return (
    <div> 
    {
          props.data.map((file, index) => {
            return (
            <Col key={`img-col-${index}`} span={2}>
              <Popover key={`pop-${index}`} content={<Img key={`img-large-${index}`} fluid={file.node.childImageSharp.small}/> } title="Preview">              
                <div>
                  <Img key={`img-${index}`} fluid={file.node.childImageSharp.small}/> 
                </div>
              </Popover>
            </Col>
             )
          })
    }      
    </div>
  )
}

const CocoDatasetSamplePage = () => {
    const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativePath: {regex: "/2019-05-29-nic/coco-sample/"}}) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
            small: fluid(maxWidth: 150) {
              src
              srcSet
              aspectRatio
              sizes
              tracedSVG
              }          
            }
          }
        }
      }
    }
    `)
    let chunked = data.allFile.edges.reduce((accumulator, current, index) => {
      let key = Object.keys(accumulator).length;
      // console.log(key, accumulator, current, index)
      if (index % 12 == 0) {
        key += 1
      }
      let array = accumulator[key] ? accumulator[key]: []
      array.push(current)
      accumulator[key] = array
      return accumulator
    }, {});
    console.log(chunked);

    return(        
      <div style={{background: "#000"}}>
        {
          Object.keys(chunked).map((key, index) => {
            return (              
                <Row key={`img-row-${index}`}>
                  <ImageGridRow data={chunked[key]} />
                </Row>              
            )
          })
        }
      </div>
    )
  }

export default CocoDatasetSamplePage;
import React from "react"
import { Row, Col, Popover, Divider } from 'antd'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from "gatsby"

import { Card, Tag } from 'antd';
import './NIC.css';

const { Meta } = Card;

const baseline_captions = [
  {"fname":"_DSC3686.jpg","caption":"Mountains and some trees next to the water"},
  {"fname":"greyjoy.jpg","caption":"A beach with pebbles and stones on it"},
  {"fname":"_DSC1877.jpg","caption":"A turtle eating some worms"},
  {"fname":"_DSC1983.jpg","caption":"The plane is about to fly"},
  {"fname":"_DSC5726.jpg","caption":"Iron man without his helmet"},
  {"fname":"IMG_1111-2.jpg","caption":"Children singing and people watching"},
  {"fname":"_DSC2522.jpg","caption":"There are eight boxes and one giant computer"},
  {"fname":"IMG_3743.jpg","caption":"Italian food on wood"},
  {"fname":"_DSC1567.jpg","caption":"A marriage about to happen"},
  {"fname":"DSC4588.jpg","caption":"A temple with flowers and a river flowing in different directions"},
  {"fname":"IMG_5757.jpg","caption":"Nighttime with bridge and bright buildings"},
  {"fname":"_DSC1854.jpg","caption":"Beach with mountains next to it"},
  {"fname":"IMG_6249.jpg","caption":"A plate with food "},
  {"fname":"G26A4540-Edit.jpg","caption":"A sunset with plane passing through"},
  {"fname":"L1000103.jpg","caption":"A taekwondo class with colorful umbrellas"},
  {"fname":"IMG_6059.jpg","caption":"Food on a wooden table and outside"},
  {"fname":"_DSC3602.jpg","caption":"Big tree with two boys next to it"},
  {"fname":"_DSC2426.jpg","caption":"Resort with a swimming pool"},
  {"fname":"DSCF3653.jpg","caption":"Buildings and one bridge connecting them"},
  {"fname":"boatsnbeach.jpg","caption":"Two boats flipped upside down on the beach"},
  {"fname":"L1000634.jpg","caption":"A very nice picture on a wall"},
  {"fname":"IMG_6971.jpg","caption":"A very old bridge with a broken truck"},
  {"fname":"G26A5654.jpg","caption":"A birthday cake on a table with people surrounding it"},
  {"fname":"_DSC0450-Edit.jpg","caption":"A rainforest with passage may be leading to treasure"},
  {"fname":"_DSC0408.jpg","caption":"A bed which is outside covered with shelter"},
  {"fname":"IMG_2490.jpg","caption":"An aeroplane landing in a different country"},
  {"fname":"IMG_1742.jpg","caption":"Windmills and water"},
  {"fname":"G26A4450.jpg","caption":"A swan about to go for a swim"},
  {"fname":"IMG_5494-2.jpg","caption":"A sunset"},
  {"fname":"IMG_4855.jpg","caption":"A panda is climbing a thick branch"},
  {"fname":"_DSC2846.jpg","caption":"A house very high on a mountain and a road leading higher in the mountain"},
  {"fname":"DSCF3784.jpg","caption":"Two red leaves and one green leaf on a table"},
  {"fname":"surf.jpg","caption":"A man surfing on a surfboard"},
  {"fname":"IMG_3849.jpg","caption":"Two adult swans and two baby swans in a pond"},
  {"fname":"_DSC2790.jpg","caption":"Many house along the road"},
  {"fname":"DSCF4454.jpg","caption":"Many lotus leaves floating in a pond"},
  {"fname":"_DSC5777.jpg","caption":"Many return gifts and a lot of yummy food"},
  {"fname":"_DSC4107.jpg","caption":"A lot of boats with a cruise boat at the end"},
  {"fname":"IMG_5668.jpg","caption":"Many people queuing up to get the burger"},
  {"fname":"L1000185-Edit.jpg","caption":"Two boy standing on grassland"},
  {"fname":"_DSC4302.jpg","caption":"A tree which is old and two barrels next to it"},
  {"fname":"IMG_2610.jpg","caption":"A building and some people crossing the street and some cars"}
]

const ImageCard = (props) => {
  const baseline_caption = baseline_captions.filter(item => item.fname === props.captions.name);
  return (
    <Card
      hoverable
      style={{ width: 400 }}
      cover={<Img key={`img-card-${props.index}`} fluid={props.file.node.childImageSharp.small} />}
    >
      {/* <Meta title="Caption" description={props.captions.greedy} /> */}
      <div style={{fontSize: 12}}>
        <div className="baseline-text">
          {/* <Tag>GREEDY</Tag> */}
          {baseline_caption && <label>{baseline_caption[0].caption}</label>}
        </div>        

        <div className="greedy-text">
          {/* <Tag>GREEDY</Tag> */}
          {props.captions && <label>{props.captions.greedy}</label>}
        </div>        
        {/* <Divider /> */}

        <div className="beam-text">
          {/* <Tag>BEAM SEARCH</Tag> */}
          {props.captions && <label>{props.captions.beam_10_h10}</label>}
        </div>
        {/* <Divider/> */}

        <div className="pure-text">
          {/* <Tag>PURE</Tag> */}
          {props.captions && <label>{props.captions.pure}</label>}
        </div>            
        {/* <Divider/> */}
        
        <div className="topk-text">
        {/* <Tag>TOP-K</Tag> */}
          {props.captions && <label>{props.captions.top_k_10}</label>}
        </div>
        <Divider/>        
        <div>
          <span className="baseline-tag">Baseline</span> &nbsp; 
          <span className="greedy-algo-tag">Greedy</span> &nbsp; 
          <span className="beam-algo-tag">Beam Search</span> &nbsp; 
          <span className="pure-algo-tag">Pure</span> &nbsp; 
          <span className="topk-algo-tag">Top-K</span> &nbsp;           
        </div>
      </div>
    </Card>
  )
}


const ImageGridRow = (props) => {
  return (
    <div>
      {
        props.data.map((file, index) => {
          return (
            <Col key={`img-col-${index}`} span={4}>
              {/* <Popover key={`pop-${index}`} content={<Img key={`img-large-${index}`} fluid={file.node.childImageSharp.small}/> } title="Preview"> */}
              <Popover key={`pop-${index}`} content={<ImageCard index={index} file={file} captions={props.captions[`${file.node.name.toLowerCase()}.jpg`]}/>} title="Generated Caption">
                <div>
                  <Img className="image-grid-preview" key={`img-${index}`} fluid={file.node.childImageSharp.small} style={{width: 120, height: 120}}/>
                </div>
              </Popover>
            </Col>
          )
        })
      }
    </div>
  )
}

const CustomImageGridWithCaption = () => {
  const data = useStaticQuery(graphql`
    {
      allShowAndTellJson {
        edges {
          node {
            name
            greedy
            beam_10_h10
            pure
            top_k_10
          }
        }
    }
      allFile(filter: {relativePath: {regex: "/2019-05-29-nic/blog_imgs/"}}) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
            small: fluid(maxWidth: 900) {
              src
              srcSet
              aspectRatio
              sizes
              tracedSVG
              }          
            }
          }
        }
      }
    }
    `)
  let captions = data.allShowAndTellJson.edges.reduce((accumulator, item) => {
    accumulator[`${item.node.name.toLowerCase()}`] = item.node
    return accumulator
  }, {});


  let chunked = data.allFile.edges.reduce((accumulator, current, index) => {
    let key = Object.keys(accumulator).length;
    // console.log(key, accumulator, current, index)
    if (index % 6 == 0) {
      key += 1
    }
    let array = accumulator[key] ? accumulator[key] : []
    array.push(current)
    accumulator[key] = array
    return accumulator
  }, {});
  console.log(chunked);

  return (
    <div style={{ background: "#000" }}>
      {
        Object.keys(chunked).map((key, index) => {
          return (
            <Row key={`img-row-${index}`}>
              <ImageGridRow data={chunked[key]} captions={captions}/>
            </Row>
          )
        })
      }
    </div>
  )
}

export default CustomImageGridWithCaption;